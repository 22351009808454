import React from "react"
import cn from "classnames"
import { SwiperOptions } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import Container from "@ecom/ui/components/Container"
import { OldCard, OldCardProps } from "../OldCard"
import img1 from "./img/img1.png"
import img3 from "./img/img3.png"
import img6 from "./img/img6.png"
import img10 from "./img/img10.png"
import img12 from "./img/img12.png"
import img18 from "./img/img18.png"
import * as styles from "./promoSingle.module.scss"

const data: {
  head: string
  items: OldCardProps[]
}[] = [
  {
    head: "дополнительные",
    items: [
      {
        color: "ff8a65",
        img: img1,
        head: "+1 месяц",
        desc1: "увеличится",
        desc2: "на 1 дополнительный месяц",
        cost: "349",
        reference: "a83b0f5e",
      },
      {
        color: "ffd740",
        img: img3,
        head: "+3 месяца",
        desc1: "увеличится",
        desc2: "на 3 дополнительных месяца",
        cost: "1 099",
        reference: "d51082d5",
      },
      {
        color: "1de9b6",
        img: img6,
        head: "+6 месяцев",
        desc1: "увеличится",
        desc2: "на 6 дополнительных месяцев",
        cost: "2 099",
        reference: "c2335746",
      },
    ],
  },
  {
    head: "фиксированные",
    items: [
      {
        color: "f8bbd0",
        img: img10,
        head: "10 месяцев",
        desc1: "станет",
        cost: "2 499",
        reference: "a04995d0",
      },
      {
        color: "ff5253",
        img: img12,
        head: "12 месяцев",
        desc1: "станет",
        cost: "3 199",
        reference: "bd510c2e",
      },
      {
        color: "81d4fa",
        img: img18,
        head: "18 месяцев",
        desc1: "станет",
        cost: "5 299",
        reference: "f331ca0f",
      },
    ],
  },
]

const params: SwiperOptions = {
  slidesPerView: "auto",
  breakpoints: {
    960: {
      allowTouchMove: false,
    },
  },
}

const PromoSingle = (
  { ...props }: OldCardProps,
  ref: React.LegacyRef<HTMLDivElement> | undefined
) => (
  <div className="root" ref={ref}>
    {data.map(({ head, items }, i) => (
      <section className={styles.section} key={i}>
        <Container>
          <h1 className={cn(styles.head, "head")}>
            Промокоды <br className="d-sm-none" /> на {head} месяцы рассрочки <br /> для покупок до
            25 000 ₽
          </h1>
          <Swiper {...params} className={styles.swiper}>
            {items.map((item, k) => (
              <SwiperSlide key={k} className={styles.slide}>
                <OldCard {...props} {...item} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Container>
      </section>
    ))}
  </div>
)

export default React.forwardRef(PromoSingle)
