import React, { useEffect } from "react"
import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"

import PageStatus from "@ecom/ui/components/PageStatus"

import { ResultPageContainer } from "../ResultPageContainer"

import * as styles from "./successPage.module.scss"

export function SuccessPage() {
  useEffect(() => {
    pushToDataLayer({
      event: "pageview",
      pageview: "coupon/success",
    })

    pushToDataLayer({
      event: "purchaseSend",
      result: "success",
      cost: localStorage.getItem("cost") || "",
      purchaseName: localStorage.getItem("purchaseName") || "",
    })
  }, [])

  const content = (
    <p className={styles.msg}>
      Промокод ожидает Вас в личном кабинете на halvacard.ru и в мобильном приложении
      &quot;Совкомбанк-Халва&quot;: раздел &quot;Еще&quot; - &quot;Промокоды&quot;
    </p>
  )

  return (
    <ResultPageContainer>
      <PageStatus success content={content} />
    </ResultPageContainer>
  )
}
