import React, { useEffect } from "react"
import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"

import PageStatus from "@ecom/ui/components/PageStatus"

import getSearchParams from "@ecom/ui/utils/getSearchParams"
import { ResultPageContainer } from "../ResultPageContainer"

import { getErrorMsgByCode } from "../../../helpers/getErrorMsgByCode"

import * as styles from "./errorPage.module.scss"

export function ErrorPage() {
  const params = getSearchParams()
  const code = params.c
  let head
  let msg: any

  let content = (
    <>
      <h3 className={styles.head}>Возможно:</h3>
      <ul className={styles.reasons}>
        <li>Сервер занят</li>
        <li>Ведутся технические работы</li>
        <li>Введен некорректный номер телефона</li>
      </ul>
    </>
  )

  if (code) {
    msg = getErrorMsgByCode(parseInt(code, 10))

    if (msg) {
      head = "Платeж отклонeн"
      content = <p className={styles.custom}>{msg}</p>
    }
  }

  useEffect(() => {
    pushToDataLayer({
      event: "pageview",
      pageview: "coupon/error",
    })

    pushToDataLayer({
      event: "purchaseSend",
      result: "error",
      cost: localStorage.getItem("cost") || "",
      purchaseName: localStorage.getItem("purchaseName") || "",
      reason: msg || "Неизвестная ошибка",
    })
  }, [msg])

  return (
    <ResultPageContainer>
      <PageStatus head={head} content={content} />
    </ResultPageContainer>
  )
}
