export function getErrorMsgByCode(code: number) {
  if ([0, 13].some((i) => i === code)) {
    return "Пожалуйста, попробуйте выполнить платeж позднее или обратитесь в Интернет-магазин."
  }
  if ([2, 6, 12].some((i) => i === code)) {
    return "Возможные причины: недостаточно средств на счeте, были указаны неверные реквизиты карты, по Вашей карте запрещены расчeты через Интернет. Пожалуйста, попробуйте выполнить платeж повторно или обратитесь в Банк, выпустивший Вашу карту."
  }
  if ([3, 4, 5, 7, 8, 9, 10, 11].some((i) => i === code)) {
    return "Пожалуйста, обратитесь в Банк, выпустивший Вашу карту."
  }
  if (code === 14) {
    return "Пожалуйста, обратитесь в платeжную систему, электронными деньгами которой Вы пытаетесь оплатить Заказ."
  }
  if ([15, 16].some((i) => i === code)) {
    return "Пожалуйста, обратитесь в Интернет-магазин."
  }

  return null
}
