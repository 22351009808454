import React, { useState, useEffect } from "react"
import cn from "classnames"

import VerifyCode from "@ecom/ui/components/VerifyCode"
import Button from "@material-ui/core/Button"

import sendRequest from "@ecom/ui/utils/sendRequest"
import getAdditionalData from "@ecom/ui/helpers/getAdditionalData"
import { FormPageContainer } from "../FormPageContainer"

import * as styles from "./verifySMS.module.scss"

export type VerifySMSProps = {
  data: any
  setPage: (page: string) => void
  setData: (data: any) => void
}

export type HelperTextProps = {
  time: number
  getNewCode: React.MouseEventHandler<HTMLSpanElement> | undefined
}

const TIMER_START = 25

function sendSms(phone: string) {
  return sendRequest(`${process.env.GATSBY_API_URL}/v1/phone/sendsmscode`, { phone })
}

function HelperText({ time, getNewCode }: HelperTextProps) {
  if (time === 0) {
    return (
      <span aria-hidden="true" className={cn(styles.helperText, styles.retry)} onClick={getNewCode}>
        Отправить код повторно
      </span>
    )
  }

  return (
    <span className={styles.helperText}>
      Отправить код повторно через <b>{time} сек</b>
    </span>
  )
}

export function VerifySMS({ data, setPage, setData }: VerifySMSProps) {
  const [token, setToken] = useState("")
  const [errorMsg, setErrorMsg] = useState("Неверный код")
  const [isError, setIsError] = useState(false)
  const [code, setCode] = useState("")
  const [time, setTime] = useState(TIMER_START)
  const [isSending, setIsSending] = useState(false)
  const { phone } = data

  useEffect(() => {
    if (phone) {
      sendSms(phone)
        .then((res: any) => setToken(res.token))
        .catch(() => {
          setTime(0)
          setIsError(true)
          setErrorMsg("Превышен лимит запросов")
        })
    }
  }, [phone])

  useEffect(() => {
    if (time > 0) {
      const timerID = setInterval(() => {
        setTime(time - 1)
      }, 1000)
      return () => clearTimeout(timerID)
    }
    return () => {}
  })

  function processPayment(tokenParam: any) {
    const addData = getAdditionalData()
    setIsSending(true)

    sendRequest(`${process.env.GATSBY_SITE_URL}/coupon/payment/`, { ...addData, ...data, tokenParam })
      .then(({ url, manyClients }: { url: any; manyClients: any }) => {
        if (manyClients) {
          setData((dataParam: any) => ({ ...dataParam, tokenParam }))
          setPage("birthday")
        } else {
          window.location.replace(url)
        }
      })
      .catch((err: any) => {
        console.log(err)
        setPage("error")
      })
  }

  function submitCode(smsCode: any) {
    sendRequest(`${process.env.GATSBY_API_URL}/v1/phone/validate`, { token, code: smsCode })
      .then(() => {
        processPayment(token)
      })
      .catch(() => {
        setIsError(true)
      })
  }

  function getNewCode() {
    setCode("")
    setIsError(false)
    sendSms(phone)
      .then((res: any) => {
        setToken(res.token)
        setTime(TIMER_START)
      })
      .catch(() => {
        setIsError(true)
        setErrorMsg("Превышен лимит запросов")
      })
  }

  function handleChange(e: any) {
    const {
      target: { value },
    } = e

    setCode(value)

    if (isError) {
      setIsError(false)
    }

    if (value.length === 4) {
      submitCode(value)
    }
  }

  function checkCode() {
    if (code.length === 4) {
      submitCode(code)
    } else {
      setIsError(true)
    }
  }

  return (
    <FormPageContainer>
      <h1 className="head">Введите код из СМС</h1>
      <p className="description">
        На Ваш номер выслан код
        <br className="d-sm-none" /> для проверки телефона:
      </p>
      <VerifyCode
        onChange={handleChange}
        value={code}
        error={isError}
        errorMsg={errorMsg}
        helperText={<HelperText time={time} getNewCode={getNewCode} />}
        classes={{
          verifyCodeInput: styles.input,
          errorTextRoot: styles.errText,
        }}
      />
      <Button className="submitBtn" onClick={checkCode} disabled={isSending}>
        Продолжить
      </Button>
    </FormPageContainer>
  )
}
