import React from "react"
import cn from "classnames"

import Container from "@ecom/ui/components/Container"

import Img from "./img/Img"
import icon1 from "./img/icon1.svg"
import icon2 from "./img/icon2.svg"
import icon3 from "./img/icon3.svg"
import icon4 from "./img/icon4.svg"
import icon5 from "./img/icon5.svg"

import * as styles from "./termsOfUse.module.scss"

const data: {
  text: string
  icon: any
}[] = [
  {
    text: "Можно активировать в течение года с момента покупки, а применить - в течение полугода с даты активации",
    icon: icon1,
  },
  {
    text: "Действует на покупки у партнеров Халвы, а так же вне партнерской сети",
    icon: icon5,
  },
  {
    text: "Необходимо применить до даты расчета платежа по рассрочке",
    icon: icon3,
  },
  {
    text: "Невозможно применить к покупке совершенной ранее покупки промокода, либо к которой ранее уже был применен другой промокод",
    icon: icon2,
  },
  {
    text: 'Нельзя применить к операциям, совершенным в разделе "Оплата услуг" в приложении «Совкомбанк – Халва»',
    icon: icon4,
  },
]

export function TermsOfUse() {
  return (
    <section className={styles.root}>
      <Container className={styles.container}>
        <div className={styles.left}>
          <Img />
        </div>
        <div>
          <h1 className={cn(styles.head, "head")}>Промокод:</h1>
          <ul>
            {data.map(({ text, icon }, i) => (
              <li key={i} style={{ backgroundImage: `url(${icon})` }} className={styles.item}>
                {text}
              </li>
            ))}
          </ul>
        </div>
      </Container>
    </section>
  )
}
