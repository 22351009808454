import React from "react"

import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"

import Button from "@material-ui/core/Button"

import clsx from "clsx"
import * as styles from "./oldCard.module.scss"

export type OldCardProps = {
  color?: string
  img?: any
  head: string
  desc1?: string
  desc2?: string
  oldCost?: string
  cost: string
  reference?: any
  setPage?: any
  setData?: any
  value?: string
  blockRassrochka?: boolean
}

export function OldCard({
  color,
  img,
  head,
  desc1,
  desc2,
  oldCost,
  cost,
  reference,
  setPage,
  setData,
  blockRassrochka = false,
}: OldCardProps) {
  function handleClick() {
    setData((data: any) => ({ ...data, reference }))
    setPage("phone")
    pushToDataLayer({
      event: "buttonClick",
      name: "Купить",
      placement: "promo_single",
      actionType: "link",
      actionDestination: "coupon/verify-phone",
      label: head,
    })
    localStorage.setItem("cost", cost)
    localStorage.setItem("purchaseName", head)
  }

  return (
    <>
      <div
        className={clsx(styles.img, { [styles.imgRassrochka]: blockRassrochka })}
        style={{
          backgroundColor: `#${color}`,
          backgroundImage: `url(${img})`,
        }}
      />
      <div className={styles.top}>
        <h2 className={styles.head}>
          {head}
          <br />
          рассрочки
        </h2>
        <p className={clsx(styles.desc, { [styles.descRassrochka]: blockRassrochka })}>
          Период рассрочки {desc1}
          <br />
          {desc2 || head}
        </p>
      </div>
      <div className={styles.rounded}>
        <div className={styles.lines} />
      </div>
      <div className={styles.bottom}>
        <div>
          {oldCost && <div className={styles.oldCost}>{oldCost} ₽</div>}
          <div className={styles.cost}>{cost} ₽</div>
        </div>
        <Button className={styles.btn} onClick={handleClick}>
          Купить
        </Button>
      </div>
    </>
  )
}
