import React, { useState, useRef } from "react"
import { graphql, PageProps } from "gatsby"
import scrollToElement from "@ecom/ui/utils/scrollToElement"
import getSearchParams from "@ecom/ui/utils/getSearchParams"
import { Layout } from "../components/Layout"
import { Banner } from "../components/coupon/Banner"
import { PromoSingle } from "../components/coupon/PromoSingle"
import { PromoSet } from "../components/coupon/PromoSet"
import { TermsOfUse } from "../components/coupon/TermsOfUse"
import { SuccessPage } from "../components/coupon/SuccessPage"
import { ErrorPage } from "../components/coupon/ErrorPage"
import { VerifyPhone } from "../components/coupon/VerifyPhone"
import { VerifySMS } from "../components/coupon/VerifySMS"
import { Birthday } from "../components/coupon/Birthday"
import { PageData } from "../interfaces/pageProps"
import { getPageData } from "../helpers/getPageData"
import { PromoRassrochka } from "../components/coupon/PromoRassrochka"

const params = getSearchParams()
const pageParam = params.p

type IndexProps = {
  type: any
  setPage: any
}

function Index({ type, setPage }: IndexProps) {
  const [data, setData] = useState({ phone: "" })
  const promoBlockRef = useRef(null)

  function scrollToPromo() {
    scrollToElement(promoBlockRef.current)
  }

  switch (type) {
    case "success":
      return <SuccessPage />
    case "error":
      return <ErrorPage />
    case "phone":
      return <VerifyPhone setPage={setPage} setData={setData} />
    case "sms":
      return <VerifySMS data={data} setPage={setPage} setData={setData} />
    case "birthday":
      return <Birthday data={data} setPage={setPage} />
    default:
      return (
        <>
          <Banner scrollToPromo={scrollToPromo} />
          <PromoSingle setPage={setPage} setData={setData} ref={promoBlockRef} head="" cost="" />
          <PromoSet setPage={setPage} setData={setData} head="" cost="" />
          <PromoRassrochka setPage={setPage} setData={setData} head="" cost="" />
          <TermsOfUse />
        </>
      )
  }
}

export default function Coupon({ data }: PageProps<PageData>) {
  const { seoData, ligal, phones } = getPageData(data)
  const [pageType, setPageType] = useState(pageParam)

  function setPage(type: any) {
    setPageType(type)
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 0)
  }
  return (
    <Layout
      headerProps={{
        PhoneProps: {
          phone: "8 800 100-77-72",
        },
      }}
      seoData={seoData}
      shortText={ligal.shortText}
      phones={phones}
    >
      <Index setPage={setPage} type={pageType} />
    </Layout>
  )
}

export const query = graphql`
  query {
    admin {
      page(url: "https://go.halvacard.ru/coupon/") {
        url
        phones
        ligal {
          text
        }
      }
    }
    pageDataJson(pageName: { eq: "coupon" }) {
      seoData {
        title
        description
      }
    }
  }
`
