import React from "react"

import Container from "@ecom/ui/components/Container"
import Button from "@material-ui/core/Button"
import Img from "./Img"
import * as styles from "./banner.module.scss"

export type BannerProps = {
  scrollToPromo?: React.MouseEventHandler<HTMLButtonElement>
}

export function Banner({ scrollToPromo }: BannerProps) {
  return (
    <section className={styles.section}>
      <Container className={styles.container}>
        <Img alt="card" className={styles.img} />
        <h1 className={styles.head}>
          Управляйте рассрочкой
          <br />
          самостоятельно!
        </h1>
        <p className={styles.desc}>
          Примените промокод к покупке, <br /> и мы увеличим период рассрочки по ней
        </p>
        <div className={styles.btnOuter}>
          <Button className={styles.btn} onClick={scrollToPromo}>
            Купить промокод
          </Button>
        </div>
      </Container>
    </section>
  )
}
