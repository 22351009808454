import React from "react"
import cn from "classnames"

import FormLanding from "@ecom/ui/components/FormLanding"
import DateField from "@ecom/ui/components/FormLanding/fields/DateField"
import SubmitButton from "@ecom/ui/components/FormLanding/fields/SubmitButton"

import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"
import sendRequest from "@ecom/ui/utils/sendRequest"
import getAdditionalData from "@ecom/ui/helpers/getAdditionalData"
import { FormPageContainer } from "../FormPageContainer"

import * as styles from "./birthday.module.scss"
import { handleClickForm } from "../../../helpers/WatcherDL/utils/handleClickForm"

export type BirthdayProps = {
  data: any
  setPage: (page: string) => void
}

export function Birthday({ data, setPage }: BirthdayProps) {
  function onSubmit({ date }: { date: string }) {
    pushToDataLayer({
      event: "GAFormEvent",
      name: "Продолжить",
      placement: "appl_halva",
      eventAction: "passportForm",
      eventCategory: "sendForm",
    })
    const addData = getAdditionalData()

    sendRequest("payment/", { ...addData, ...data, date })
      .then((result: { url: string | URL }) => {
        window.location.replace(result.url)
      })
      .catch((err: any) => {
        console.log(err)
        setPage("error")
      })
  }

  const onChangeFields = {
    date: () => {
      handleClickForm()
    },
  }

  return (
    <FormPageContainer>
      <h1 className="head">
        Введите дату
        <br className="d-sm-none" /> Вашего рождения
      </h1>
      <p className="description">
        Это необходимо, чтобы корректно идентифицировать Вас
        <br className="d-none d-sm-block" /> как клиента и настроить промокод именно Вам
      </p>
      <FormLanding onSubmit={onSubmit} onChangeFields={onChangeFields}>
        <DateField autoFocus name="date" className="formControl" label="Дата рождения" />
        <SubmitButton className={cn(styles.btn, "submitBtn")}>Продолжить</SubmitButton>
      </FormLanding>
    </FormPageContainer>
  )
}
