import React from "react"
import cn from "classnames"

import { SwiperOptions } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { ButtonProps } from "@material-ui/core/Button"
import Container from "@ecom/ui/components/Container"
import { Card, CardProps } from "../Card"

import * as styles from "./promoSet.module.scss"

type ItemsType = {
  iconText: string
  head: string
  desc: string
  cost: string
  reference: string
  className?: ButtonProps["color"]
}[]

const items: ItemsType = [
  {
    iconText: "S",
    head: "Набор S",
    desc: "Дополнительные +1, +3 и +6 месяцев рассрочки для 3-х покупок",
    cost: "3 199",
    reference: "e3d9ebac",
  },
  {
    className: "secondary",
    iconText: "M",
    head: "Набор M",
    desc: "Дополнительные +3, +6 и фиксированные 10 месяцев рассрочки для 3-х покупок",
    cost: "5 099",
    reference: "d59f1572",
  },
  {
    iconText: "L",
    head: "Набор L",
    desc: "Фиксированные 10, 12, 18 месяцев рассрочки для 3-х покупок",
    cost: "9 899",
    reference: "a50013b4",
  },
]

const params: SwiperOptions = {
  slidesPerView: "auto",
  breakpoints: {
    960: {
      allowTouchMove: false,
    },
  },
}

export function PromoSet(props: CardProps) {
  return (
    <section className={styles.root}>
      <Container>
        <h1 className={cn(styles.head, "head")}>
          Наборы промокодов <br /> для покупок до 25 000 ₽
        </h1>
        <Swiper {...params} className={styles.swiper}>
          {items.map((data, i) => (
            <SwiperSlide key={i} className={cn(styles.slide, data.className)}>
              <div className={cn(styles.cap, styles.cap1)} />
              <div className={styles.cap} />
              <div className={styles.cardOuter}>
                <Card {...props} {...data} />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
    </section>
  )
}
