// extracted by mini-css-extract-plugin
export var top = "card-module--top--9NA3P";
export var bottom = "card-module--bottom--yM9-N";
export var discount = "card-module--discount--uXYjZ";
export var icon = "card-module--icon--m2J3B";
export var desc = "card-module--desc--99mHl";
export var oldCost = "card-module--oldCost--D4X7w";
export var rounded = "card-module--rounded--2CeVc";
export var head = "card-module--head--UzoCk";
export var lines = "card-module--lines--JZWvh";
export var cost = "card-module--cost--4sHGq";
export var btn = "card-module--btn--SW1N-";