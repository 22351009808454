// extracted by mini-css-extract-plugin
export var img = "oldCard-module--img--iOvJt";
export var imgRassrochka = "oldCard-module--imgRassrochka--yQ4BV";
export var discount = "oldCard-module--discount--i1ngI";
export var top = "oldCard-module--top--v7+MK";
export var bottom = "oldCard-module--bottom--0tUpu";
export var head = "oldCard-module--head--CpTNn";
export var desc = "oldCard-module--desc--3aieR";
export var descRassrochka = "oldCard-module--descRassrochka--1SLXp";
export var lines = "oldCard-module--lines--mNY5a";
export var rounded = "oldCard-module--rounded--lilDb";
export var oldCost = "oldCard-module--oldCost--8y+rP";
export var cost = "oldCard-module--cost--7-Gg2";
export var btn = "oldCard-module--btn--kplnU";