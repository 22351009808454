import { OldCardProps } from "../OldCard"

import {
  Chair,
  Bag,
  Shoes,
  Tool,
  Iphone,
  Clothes,
  RedCar,
  YellowGlasses,
  Gamepad,
  Watches,
  PinkBottle,
  Couch,
  Ring,
  HeadPhones,
  Paint,
} from "./img/index"

export const SELECT_VALUES = {
  TO_35RUB: "35 max",
  TO_45RUB: "45 max",
  TO_55RUB: "55 max",
  TO_65RUB: "65 max",
  TO_75RUB: "75 max",
  ALL: "all",
}

export const rassrochkaData: OldCardProps[] = [
  {
    color: "C7BBF8",
    img: Chair,
    head: "10 месяцев",
    desc1: "станет 10 месяцев",
    desc2: "при покупке от 25 000 до 35 000 рублей",
    cost: "3 499",
    value: SELECT_VALUES.TO_35RUB,
    reference: "14299635790",
  },
  {
    color: "9ED1FF",
    img: Bag,
    head: "12 месяцев",
    desc1: "станет 12 месяцев",
    desc2: "при покупке от 25 000 до 35 000 рублей",
    cost: "4 499",
    value: SELECT_VALUES.TO_35RUB,
    reference: "14299682080",
  },
  {
    color: "FF7676",
    img: Shoes,
    head: "18 месяцев",
    desc1: "станет 18 месяцев",
    desc2: "при покупке от 25 000 до 35 000 рублей",
    cost: "7 399",
    value: SELECT_VALUES.TO_35RUB,
    reference: "14299699583",
  },
  {
    color: "FFE76C",
    img: Tool,
    head: "10 месяцев",
    desc1: "станет 10 месяцев",
    desc2: "при покупке от 35 000 до 45 000 рублей",
    cost: "4 499",
    value: SELECT_VALUES.TO_45RUB,
    reference: "14299719401",
  },
  {
    color: "9288FF",
    img: Iphone,
    head: "12 месяцев",
    desc1: "станет 12 месяцев",
    desc2: "при покупке от 35 000 до 45 000 рублей",
    cost: "5 699",
    value: SELECT_VALUES.TO_45RUB,
    reference: "14299738152",
  },
  {
    color: "CAF289",
    img: Clothes,
    head: "18 месяцев",
    desc1: "станет 18 месяцев",
    desc2: "при покупке от 35 000 до 45 000 рублей",
    cost: "9 499",
    value: SELECT_VALUES.TO_45RUB,
    reference: "14299764998",
  },
  {
    color: "FF5B5F",
    img: RedCar,
    head: "10 месяцев",
    desc1: "станет 10 месяцев",
    desc2: "при покупке от 45 000 до 55 000 рублей",
    cost: "5 399",
    value: SELECT_VALUES.TO_55RUB,
    reference: "14299779989",
  },
  {
    color: "FFEB57",
    img: YellowGlasses,
    head: "12 месяцев",
    desc1: "станет 12 месяцев",
    desc2: "при покупке от 45 000 до 55 000 рублей",
    cost: "6 999",
    value: SELECT_VALUES.TO_55RUB,
    reference: "14299798315",
  },
  {
    color: "61D0FF",
    img: Gamepad,
    head: "18 месяцев",
    desc1: "станет 18 месяцев",
    desc2: "при покупке от 45 000 до 55 000 рублей",
    cost: "11 599",
    value: SELECT_VALUES.TO_55RUB,
    reference: "14299807825",
  },
  {
    color: "A5D5FF",
    img: Watches,
    head: "10 месяцев",
    desc1: "станет 10 месяцев",
    desc2: "при покупке от 55 000 до 65 000 рублей",
    cost: "6 399",
    value: SELECT_VALUES.TO_65RUB,
    reference: "14299821293",
  },
  {
    color: "E3AAFC",
    img: PinkBottle,
    head: "12 месяцев",
    desc1: "станет 12 месяцев",
    desc2: "при покупке от 55 000 до 65 000 рублей",
    cost: "8 199",
    value: SELECT_VALUES.TO_65RUB,
    reference: "14299841481",
  },
  {
    color: "FF9153",
    img: Couch,
    head: "18 месяцев",
    desc1: "станет 18 месяцев",
    desc2: "при покупке от 55 000 до 65 000 рублей",
    cost: "13 699",
    value: SELECT_VALUES.TO_65RUB,
    reference: "14299861448",
  },
  {
    color: "45E2DD",
    img: Ring,
    head: "10 месяцев",
    desc1: "станет 10 месяцев",
    desc2: "при покупке от 65 000 до 75 000 рублей",
    cost: "7 399",
    value: SELECT_VALUES.TO_75RUB,
    reference: "14299874114",
  },
  {
    color: "FAD2F2",
    img: HeadPhones,
    head: "12 месяцев",
    desc1: "станет 12 месяцев",
    desc2: "при покупке от 65 000 до 75 000 рублей",
    cost: "9 499",
    value: SELECT_VALUES.TO_75RUB,
    reference: "14299886382",
  },
  {
    color: "FA3C3C",
    img: Paint,
    head: "18 месяцев",
    desc1: "станет 18 месяцев",
    desc2: "при покупке от 65 000 до 75 000 рублей",
    cost: "15 799",
    value: SELECT_VALUES.TO_75RUB,
    reference: "14299901089",
  },
]
