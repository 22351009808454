import React from "react"
import cn from "classnames"

import FormLanding from "@ecom/ui/components/FormLanding"
import PhoneField from "@ecom/ui/components/FormLanding/fields/PhoneField"
import SubmitButton from "@ecom/ui/components/FormLanding/fields/SubmitButton"

import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"
import { FormPageContainer } from "../FormPageContainer"

import * as styles from "./verifyPhone.module.scss"
import { handleClickForm } from "../../../helpers/WatcherDL/utils/handleClickForm"

export type VerifyPhoneProps = {
  setPage: (page: string) => void
  setData: (data: any) => void
}

export function VerifyPhone({ setPage, setData }: VerifyPhoneProps) {
  function onSubmit({ phone }: { phone: string }) {
    pushToDataLayer({
      event: "GAFormEvent",
      name: "Продолжить",
      placement: "appl_halva",
      eventAction: "passportForm",
      eventCategory: "sendForm",
    })
    setData((data: any) => ({ ...data, phone }))
    setPage("sms")
  }

  const onChangeFields = {
    phone: () => {
      handleClickForm()
    },
  }

  return (
    <FormPageContainer>
      <h1 className={cn(styles.title, "head")}>
        Введите номер телефона,
        <br />
        который указывали <br className="d-md-none" /> при оформлении Халвы
      </h1>
      <FormLanding onSubmit={onSubmit} onChangeFields={onChangeFields}>
        <PhoneField autoFocus name="phone" className="formControl" />
        <SubmitButton className={cn(styles.btn, "submitBtn")}>Продолжить</SubmitButton>
      </FormLanding>
    </FormPageContainer>
  )
}
