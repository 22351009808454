import React from "react"

import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"

import Button, { ButtonProps } from "@material-ui/core/Button"
import Box from "@material-ui/core/Box"

import * as styles from "./card.module.scss"

export type CardProps = {
  className?: ButtonProps["color"]
  head: string
  iconText?: string
  desc?: string
  oldCost?: string
  cost: string
  reference?: any
  setPage: (page: string) => void
  setData: (data: any) => void
}

export function Card({
  className,
  head,
  iconText,
  desc,
  oldCost,
  cost,
  reference,
  setPage,
  setData,
}: CardProps) {
  function handleClick() {
    setData((data: any) => ({ ...data, reference }))
    setPage("phone")
    pushToDataLayer({
      event: "buttonClick",
      name: "Купить",
      placement: "promo_set",
      actionType: "link",
      actionDestination: "coupon/verify-phone",
      label: head,
    })
    localStorage.setItem("cost", cost)
    localStorage.setItem("purchaseName", head)
  }

  return (
    <>
      {/* <div className={styles.discount}>Скидка 10%</div> */}
      <div className={styles.top}>
        <Box display="flex" justifyContent="space-between">
          <h2 className={styles.head}>{head}</h2>
          <div className={styles.icon}>{iconText}</div>
        </Box>
        <p className={styles.desc}>{desc}</p>
      </div>
      <Box display="flex" alignItems="center" className={styles.rounded}>
        <div className={styles.lines} />
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        className={styles.bottom}
      >
        <div>
          {oldCost && <div className={styles.oldCost}>{oldCost} ₽</div>}
          <div className={styles.cost}>{cost} ₽</div>
        </div>
        <Button color={className} className={styles.btn} onClick={handleClick}>
          Купить
        </Button>
      </Box>
    </>
  )
}
