import React, { useState, useEffect } from "react"
import Container from "@ecom/ui/components/Container"
import Select from "@ecom/ui/components/SelectCoupons"
import { SwiperOptions, Grid } from "swiper"
import clsx from "clsx"
import { OldCardProps } from "../OldCard"

import { rassrochkaData, SELECT_VALUES } from "./cardsInfo"
import * as styles from "./promoRassrochka.module.scss"
import { PromoRassrochkaSlider } from "./slider"

const options = [
  {
    label: (
      <>
        при покупке:&nbsp;<strong>25 000 - 35 000 ₽</strong>
      </>
    ),
    value: SELECT_VALUES.TO_35RUB,
  },
  {
    label: (
      <>
        при покупке:&nbsp;<strong>35 000 - 45 000 ₽</strong>
      </>
    ),
    value: SELECT_VALUES.TO_45RUB,
  },
  {
    label: (
      <>
        при покупке:&nbsp;<strong>45 000 - 55 000 ₽</strong>
      </>
    ),
    value: SELECT_VALUES.TO_55RUB,
  },
  {
    label: (
      <>
        при покупке:&nbsp;<strong>55 000 - 65 000 ₽</strong>
      </>
    ),
    value: SELECT_VALUES.TO_65RUB,
  },
  {
    label: (
      <>
        при покупке:&nbsp;<strong>65 000 - 75 000 ₽</strong>
      </>
    ),
    value: SELECT_VALUES.TO_75RUB,
  },
  { label: "показать все", value: SELECT_VALUES.ALL },
]

export function PromoRassrochka({ ...props }: OldCardProps) {
  const [selectedValue, setSelectedValue] = useState<string>(SELECT_VALUES.TO_35RUB)
  const [filteredPromos, setFilteredPromos] = useState<OldCardProps[]>()
  const checkRows = filteredPromos?.length === 3

  const params: SwiperOptions = {
    modules: [Grid],
    slidesPerView: 3,
    watchOverflow: true,
    parallax: true,
    updateOnWindowResize: true,
    wrapperClass: clsx(styles.secondWrapper, { [styles.removeWrap]: checkRows }),
    grid: {
      rows: checkRows ? 1 : 5,
      fill: "row",
    },
    breakpoints: {
      1280: {
        allowTouchMove: false,
        spaceBetween: 24,
        slidesPerView: 3,
      },
      960: {
        allowTouchMove: false,
        spaceBetween: 24,
        slidesPerView: 3,
      },
      600: {
        slidesPerView: "auto",
        spaceBetween: 24,
        allowTouchMove: true,
        grid: {
          rows: checkRows ? 1 : 5,
          fill: "row",
        },
      },
      360: {
        spaceBetween: 20,
        slidesPerView: 1.2,
        allowTouchMove: true,
        grid: {
          rows: 1,
          fill: "row",
        },
      },
    },
  }

  const chooseCategorie = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(e.target.value)
  }

  useEffect(() => {
    const newItems = [...rassrochkaData]
    const changedList = newItems.filter((item) => item.value === selectedValue)
    setFilteredPromos(changedList)
    if (selectedValue === SELECT_VALUES.ALL) setFilteredPromos(rassrochkaData)
  }, [selectedValue])

  return (
    <section className={styles.section}>
      <Container className={styles.container}>
        <h1 className={styles.title}>Мега рассрочка</h1>
        <div className={styles.selectWrapper}>
          <Select onChange={chooseCategorie} value={selectedValue} options={options} />
        </div>
        <PromoRassrochkaSlider {...props} {...params} filteredPromos={filteredPromos} />
      </Container>
    </section>
  )
}
