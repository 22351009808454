import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { OldCard, OldCardProps } from "../../OldCard"

export function PromoRassrochkaSlider({ paramsDesktop, filteredPromos, ...props }: any) {
  return (
    <Swiper {...props}>
      {filteredPromos?.map((item: OldCardProps, i: number) => (
        <SwiperSlide key={i}>
          <OldCard blockRassrochka {...props} {...item} />
        </SwiperSlide>
      ))}
    </Swiper>
  )
}
